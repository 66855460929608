<template>
  <div class="staff">
    <el-button class="go_back" @click="goback()"><i class="el-icon-back"></i> 返回</el-button>
    <p class="tips">
      说明：本页可为“<b>当前热点标签</b>”批量添加文章<br />
      1.通过搜索筛选文章；2.选中所需添加标签的文章（一次最多处理50条）；3执行【添加热点标签】；
    </p>
    <el-form
      :model="searchForm"
      :inline="true"
      ref="searchForm"
      class="searchForm"
    >
      <el-form-item label="文章标题:">
        <el-input
          v-model="searchForm.Title"
          placeholder="输入文章标题"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="searchDate()" type="primary">
          <i class="el-icon-search" style="margin-right: 5px"></i>
          搜索
        </el-button>
        <el-button @click="resetForm()">
          <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
          重置
        </el-button>
      </el-form-item>
    </el-form>

    <p class="label_box">
      当前热点标签：<b style="font-size:16px;">{{ $route.query.labelName }}</b>
    </p>
    <el-button class="add_hot" type="primary" @click="addHotLabel()">添加热点标签</el-button>
    <!-- 处理分页 :data="PageConfigure.slice((PageIndex - 1) * PageSize, PageIndex * PageSize)" -->
    <el-table
      border
      :data="PageConfigure"
      row-key="Id"
      style="width: 100%;margin-top:15px;"
      :default-sort="{ prop: 'date', order: 'descending' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        label="序号"
        width="80"
        align="center"
        type="index"
        sortable
      >
      </el-table-column>
      <el-table-column
        label="文章标题"
        prop="Title"
        align="center"
      ></el-table-column>
      <el-table-column label="文章类型" width="150" prop="CategoryName" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.CategoryName ? scope.row.CategoryName : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="作者" width="150" prop="Author" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.Author ? scope.row.Author : "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="推荐位置"
        width="150"
        prop="RecommendPosition"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{scope.row.RecommendPosition ? scope.row.RecommendPosition : "--"}}</span>
        </template>
      </el-table-column>
      <el-table-column label="城市" prop="City" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.City ? scope.row.City : "--" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="PageSize"
        background
        :total="PageConfigure.length"
      >
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      PageConfigure: [], //列表分页数据
      //   PageIndex: 1,
      //   PageSize: 10,
      searchForm: {
        Title: ""
      },
      //表格选择数据
      checkboxSelectList: []
    };
  },
  created() {
    // console.log(this.$route.query);
    this.searchDate();
  },
  methods: {
    //返回上一页
    goback() {
      this.searchForm.Title = '';
      this.$router.push({ path: "/Hotspotlabel" });
    },
    searchDate() {
      let keyword = this.searchForm.Title;
      this.$get(this.$api.GetNoneLabelArtcle, { keyword }).then(res => {
        this.PageConfigure = res;
      });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.PageIndex = val;
    },
    handleSizeChange(val) {
      this.PageSize = val;
    },
    resetForm() {
      this.searchForm.Title = "";
      this.searchDate();
    },
    // 多选按钮
    handleSelectionChange(val) {
      console.log(val);
      let idList = [];
      val.forEach(item => {
        idList.push(item.Id);
      });
      this.checkboxSelectList = idList;
    },
    addHotLabel() {
      let hotLabelId = parseInt(this.$route.query.id);
      let idList = this.checkboxSelectList;
      this.$post(
        this.$api.SetArticlesHotLabel + "?hotLabelId=" + hotLabelId,
        idList
      ).then(res => {
        if (res.IsSuccess) {
          this.$message.success("添加成功");
          this.searchDate();
        } else {
          this.$message.error("添加失败");
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 20px 20px 30px;
  border-radius: 10px;
 .go_back{

 }
 .tips{
    display: block;
    font-size:14px;
    line-height: 25px;
    margin:15px auto;
 }
 .label_box{
    display: inline-block;
 }
 .add_hot{
    margin-left: 20px;
 }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}
// 小星星
.xiaoxx {
  color: #F56C6C;
  position: absolute;
  top: 0;
  left: -10px;
}
</style>
